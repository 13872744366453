@import '~@atlas-design-system/tokens/dist/tokens.css';
@import '~@atlas-design-system/core/dist/styles/main.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0A121A !important;
}

.chart {
  max-height: 35vh;
  min-height: 20em;
  margin-bottom: 6em;
  text-align: center;
}

.chart button {
  margin: 1.5em 0.2em;
}

.chart + h3 {
  text-align: center;
}

.spinner {
  position: relative;
  margin: auto;
  width: 20em;
  height: 20em;
}

.datepicker {
  width: 20em;
  display: flex;
  flex-direction: row;
  margin: auto;
}

.datepicker div {
  margin: 0 0.1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.recordLocatorForm{
  margin:3px;
}

.formDatePicker div div {
  z-index: 11;
  margin-right: 5px;
  height: 37px;
}

.hideDisplay {
  display: none !important
}

.headerContent {
  flex-grow: 1;
  text-align: left;
  margin:12px;
  color: #F7E4CB
}

.headerAccount {
  display: flex;
  text-align: right;
  flex-grow: 1;
  color: #F7E4CB
}

.accountContent {
  flex-grow: 1;
  text-align: right;
  margin-top: 14px;
  color: #F7E4CB
}

.centerContent {
  align-items: center;
  vertical-align: middle !important;
}

.MuiPickersDay-current{
  color: #042914 !important;
  font-weight: bold;
}